
<template>
  <div>
      <vx-card>
        <div class="vx-row ">
          <div class="vx-col w-2/2">
            <label for="">{{ $t('codigo-convite-classname', [className]) }}</label>
            <vx-input-group>
              <vs-input
                v-model="invite_code"
                name="invite_code"
                v-validate="'required'"
                v-mask="'XXXX-XXXX'"
                @input="bounceClass"
              />

              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button color="primary" @click="subscribe" :disabled="invalidInvite">{{ $t('inscrever') }}</vs-button>
                </div>
              </template>
            </vx-input-group>

            <span class="text-danger text-sm" v-show="errors.has('invite_code')">{{
              errors.first('invite_code')
            }}</span>
          </div>
        </div>
      </vx-card>
  </div>
</template>

<script>
import ClassService from '@/services/api/ClassService'
import FirebaseChatService from '@/services/firebase/FirebaseChatService'
import User from '@/chat/firestore/models/User'

export default {
  props: {
    invite_code: {
      type: String,
      default: null
    }
  },
  data: () => ({
    classService: null,
    className: null,
    chatService: null
  }),
  computed: {
    invalidInvite() {
      return (this.isEmpty(this.className) || this.isEmpty(this.invite_code))
    }
  },
  methods: {
    bounceClass() {
      if (!this.isEmpty(this.invite_code)) {
        this.className = null
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.$vs.loading()
          this.classService.checkInvitationCode(this.invite_code).then(
            data => {
              this.$set(this.$data, 'className', data.className)
              this.$vs.loading.close()
            },
            error => {
              this.$vs.loading.close()
              this.showErrors(this.$validator, error)
            }
          )
        }, 1000)
      }
    },
    subscribe() {
      this.$vs.loading()
      this.classService.subscribeClass(this.invite_code)
        .then(
          classData => {
            const chatUser = User.fromDBUser(this.$store.getters.AppActiveUser)
            this.chatService.assignUserToClassChat(classData.id, chatUser)
            this.$vs.loading.close()
            this.notifySuccess(this.$vs)
            this.$router.push('/classes_students')
          },
          error => {
            this.$vs.loading.close()
            this.showErrors(this.$validator, error)
          }
        )
    }
  },
  beforeMount() {
    this.classService = ClassService.build(this.$vs)
    this.chatService = FirebaseChatService.build(this.$vs)
    this.bounceClass()
  }
}
</script>