var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vx-card", [
        _c("div", { staticClass: "vx-row " }, [
          _c(
            "div",
            { staticClass: "vx-col w-2/2" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(
                  _vm._s(_vm.$t("codigo-convite-classname", [_vm.className]))
                ),
              ]),
              _c(
                "vx-input-group",
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: "XXXX-XXXX",
                        expression: "'XXXX-XXXX'",
                      },
                    ],
                    attrs: { name: "invite_code" },
                    on: { input: _vm.bounceClass },
                    model: {
                      value: _vm.invite_code,
                      callback: function ($$v) {
                        _vm.invite_code = $$v
                      },
                      expression: "invite_code",
                    },
                  }),
                  _c("template", { slot: "append" }, [
                    _c(
                      "div",
                      { staticClass: "append-text btn-addon" },
                      [
                        _c(
                          "vs-button",
                          {
                            attrs: {
                              color: "primary",
                              disabled: _vm.invalidInvite,
                            },
                            on: { click: _vm.subscribe },
                          },
                          [_vm._v(_vm._s(_vm.$t("inscrever")))]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("invite_code"),
                      expression: "errors.has('invite_code')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("invite_code")))]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }